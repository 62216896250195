<template>
  <div class="table-container">
    <VcaTable
      store="takings"
      :list="pg_list"
      :colspan="8"
      :searchLabel="$t('taking.list.search')"
    >
      <template v-slot:colspan>
        <col width="12%" />
        <col width="12%" />
        <col width="12%" />
        <col width="8%" />
        <col width="4%" />
        <col width="4%" />
        <col width="4%" />
        <col width="1%" />
      </template>
      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('taking.list.filter')">
          <div slot="body">
            <TakingFilter v-model="filter" @reset="resetFilter()" />
          </div>
        </vca-accordion>
      </template>
      <template v-slot:desktop-options> </template>
      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('name')">
          <label> {{ $t("taking.list.name") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event.name')">
          <label> {{ $t("taking.list.to_event") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('date_of_taking')">
          <label> {{ $t("taking.list.date_of_taking") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.open") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.wait") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.confirmed") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.name')">
          <label> {{ $t("taking.list.crew_name") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>
      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          @click="setCurrent(res)"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <a :href="takingLink(res.id)"> {{ takingName(res) }}</a>
          </td>
          <td class="vca-table-cell">
            <a :href="eventLink(res.event.id)"> {{ res.event.name }}</a>
          </td>
          <td class="vca-table-cell">
            {{ dataOfTaking(res.date_of_taking) }}
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.open, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.wait, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.confirmed, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.crew.name }} </label>
          </td>

          <td class="vca-table-cell">
            <vca-row>
              <label @click="edit(res.id)"
                ><img
                  class="editable"
                  src="@/assets/icons/edit.png"
                  :title="$t('button.edit')"
                  :alt="$t('button.edit')"
              /></label>
              <label
                class="editable"
                @click="remove(res.id)"
                v-if="res.event.id == ''"
                ><img
                  class="editable"
                  src="@/assets/icons/delete.png"
                  :title="$t('button.delete')"
                  :alt="$t('button.delete')"
              /></label>
            </vca-row>
          </td>
        </tr>
      </template>
      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("taking.list.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('taking.list.filter')"
          @close="do_filter = false"
        >
          <TakingFilter v-model="filter" />
        </vca-popup>
      </template>
      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('name')">
          <label>{{ $t("taking.list.name") }} &varr;</label><br />
          <label>{{ $t("taking.list.amounts") }}</label>
        </div>
      </template>
      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <a :href="takingLink(res.id)">
                  {{ takingName(res) }}
                </a>
              </vca-row>
              <vca-row>
                {{ formatMoneyStatus(res.state.open, res.state.no_income) }} /
                {{ formatMoneyStatus(res.state.wait, res.state.no_income) }} /
                {{
                  formatMoneyStatus(res.state.confirmed, res.state.no_income)
                }}
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import TakingFilter from "@/components/finance/takings/list/TakingFilter";
export default {
  name: "TakingTable",
  components: { VcaTable, TakingFilter },
  data() {
    return {
      store: "takings/pg",
      initial: true,
      do_filter: false,
      currentFilter: {
        type_of_event: [],
        event_end_to: undefined,
        event_end_from: undefined,
      },
      currentTextFilter: "",
      updateCount: 0,
    };
  },
  watch: {
    "pg.currentPage": {
      handler() {
        this.$store.dispatch({ type: `${this.store}/request` });
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        this.$store.commit(`${this.store}/pageSize`, value);
        if (this.pg.currentPageSize == 1) {
          this.$store.dispatch({ type: `${this.store}/request` });
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
  },
  created() {
    this.resetFilter();
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      pg_list: "takings/pg/list",
    }),
    emptyFilter() {
      return !this.pg.filter || this.pg.filter == "";
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    create() {
      this.$router.push({ name: "TakingsAdd" });
    },
    incUpdateCount() {
      this.updateCount += 1;
    },
    edit(value) {
      this.$router.push({ path: "/finances/takings/edit/" + value });
    },
    remove(value) {
      this.$store
        .dispatch({ type: "takings/deleteByID", data: value })
        .then(() => {
          this.$store.dispatch({ type: `${this.store}/request` });
        });
    },
    resetFilter() {
      this.filter = { ...this.currentFilter };
    },
    formatMoneyStatus(value, no_income) {
      if (no_income) {
        return this.$t("taking.list.no_income");
      } else {
        return value.amount / 100 + " €";
      }
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("takings/current", null);
      } else {
        this.$store.commit("takings/current", value);
      }
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
    dataOfTaking(value) {
      if (value === 0) {
        return "-";
      } else {
        return this.date(value);
      }
    },
  },
};
</script>
